import {Component} from '@angular/core';

export enum WidgetPage {
  poem = 0,
  photo = 1,
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
  protected readonly WidgetPage = WidgetPage;
  selectedIndex: number = 0;

  selectedTabChanged(index: number): void {
    this.selectedIndex = index;
  }
}
