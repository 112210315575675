<div class="container">

  <mat-radio-group class="radio-group" [(ngModel)]="selectedFilter" (change)="setFilteredPoems()">
    <mat-radio-button class="radio-button" [value]="Accept.Posted">未判定</mat-radio-button>
    <mat-radio-button class="radio-button" [value]="Accept.Accepted">採用</mat-radio-button>
    <mat-radio-button class="radio-button" [value]="Accept.Rejected">不採用</mat-radio-button>
  </mat-radio-group>

  <table mat-table matSort [dataSource]="dataSource" class="common-table mat-elevation-z8">
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="user_id">User Id</th>
      <td mat-cell *matCellDef="let poem">{{ poem.user_id === -1 ? '' : poem.user_id }}</td>
      <td mat-footer-cell *matFooterCellDef>Total</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">ニックネーム</th>
      <td mat-cell *matCellDef="let poem">{{ poem.name }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="upper_phrase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="upper_phrase">スポーツ川柳</th>
      <td mat-cell
          *matCellDef="let poem">{{ poem.upper_phrase }} {{ poem.middle_phrase }} {{ poem.lower_phrase }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="posted_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="posted_at">投稿日</th>
      <td mat-cell *matCellDef="let poem">{{ poem.posted_at | date:'yyyy/M/d' }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="accept">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="accept">判定</th>
      <td mat-cell *matCellDef="let poem">
        <mat-select *ngIf="poem.accept === Accept.Posted" [(ngModel)]="poem.accept">
          <mat-option [value]="Accept.Accepted">採用</mat-option>
          <mat-option [value]="Accept.Rejected">不採用</mat-option>
          <mat-option [value]="Accept.Posted">未判定</mat-option>
        </mat-select>
        <mat-select *ngIf="poem.accept !== Accept.Posted" [(ngModel)]="poem.accept">
          <mat-option [value]="Accept.Accepted">採用</mat-option>
          <mat-option [value]="Accept.Rejected">不採用</mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef>{{ dataSource.data.length }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let poem; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-footer"></tr>
  </table>

  <div class="footer-action-container">
    <button mat-raised-button color="primary" (click)="save()">保存
    </button>
  </div>
</div>
