<div class="container">
  <mat-tab-group (selectedIndexChange)="selectedTabChanged($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="スポーツ川柳">
      <app-poem *ngIf="selectedIndex === WidgetPage.poem"></app-poem>
    </mat-tab>
    <mat-tab label="日本を走ろう">
      <app-run-nippon *ngIf="selectedIndex === WidgetPage.photo"></app-run-nippon>
    </mat-tab>
  </mat-tab-group>
</div>
