import {Component, OnInit, ViewChild} from '@angular/core';
import {Accept, Poem} from '../../../../core/model/resources/widget';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import {PoemApiService} from '../../../../core/services/poem-api.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-poem',
  templateUrl: './poem.component.html',
  styleUrl: './poem.component.scss'
})
export class PoemComponent implements OnInit {

  protected readonly Accept = Accept;

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  displayedColumns = ['user_id', 'name', 'upper_phrase', 'posted_at', 'accept'];
  dataSource: MatTableDataSource<Poem> = new MatTableDataSource();
  beforePoems: Poem[] = [];
  selectedFilter = Accept.Posted;

  constructor(private poemApiService: PoemApiService,
              private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.sort) {
      throw new Error('sort is not set');
    }

    this.dataSource.sort = this.sort;
    this.beforePoems = await this.poemApiService.getPoems();
    await this.setFilteredPoems();
  }


  async setFilteredPoems(): Promise<void> {
    const changedPoems = this.getChangedPoems();
    if (changedPoems.length !== 0) {
      const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '保存していない変更は破棄されます。よろしいですか？');
      if (!isOk) {
        return;
      }
    }
    const poems: Poem[] = JSON.parse(JSON.stringify(this.beforePoems));
    this.dataSource.data = poems.filter(poem => poem.accept === this.selectedFilter);
  }

  private getChangedPoems(): Poem[] {
    const result: Poem[] = [];
    this.beforePoems.forEach(beforePoem => {
      const changed = this.dataSource.data.find(poem => poem.user_id === beforePoem.user_id &&
        poem.posted_at === beforePoem.posted_at && poem.accept !== beforePoem.accept);
      if (changed) {
        result.push(changed);
      }
    });
    return result;
  }

  async save(): Promise<void> {
    const changedPoems = this.getChangedPoems();
    if (changedPoems.length === 0) {
      MessageDialogComponent.createWarningDialog(this.dialog, '変更がありません。');
      return;
    }
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '変更を保存します。よろしいですか？');
    if (!isOk) {
      return;
    }

    await this.poemApiService.patchPoems(changedPoems);
    this.beforePoems = await this.poemApiService.getPoems();
    await this.setFilteredPoems();
  }

}
