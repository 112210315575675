<div class="container">

  <mat-radio-group class="radio-group" [(ngModel)]="selectedFilter" (change)="setFiltered()">
    <mat-radio-button class="radio-button" [value]="Accept.Posted">未判定</mat-radio-button>
    <mat-radio-button class="radio-button" [value]="Accept.Accepted">採用</mat-radio-button>
    <mat-radio-button class="radio-button" [value]="Accept.Rejected">不採用</mat-radio-button>
  </mat-radio-group>

  <table mat-table matSort [dataSource]="dataSource" class="common-table mat-elevation-z8">
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="user_id">User Id</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.user_id === -1 ? '' : runNippon.user_id }}</td>
      <td mat-footer-cell *matFooterCellDef>Total</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">ニックネーム</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.name }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="title">タイトル</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.title }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="place">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="place">場所</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.place }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="detail">詳細</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.detail }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="posted_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="posted_at">投稿日</th>
      <td mat-cell *matCellDef="let runNippon">{{ runNippon.posted_at | date:'yyyy/M/d' }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="v_thumbnail_url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="v_thumbnail_url">縦サムネイル</th>
      <td mat-cell *matCellDef="let runNippon">
        <a [href]="runNippon.url" target="_blank"><img src="{{runNippon.v_thumbnail_url}}" width="200" alt="写真"/></a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="h_thumbnail_url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="h_thumbnail_url">横サムネイル</th>
      <td mat-cell *matCellDef="let runNippon">
        <a [href]="runNippon.url" target="_blank"><img src="{{runNippon.h_thumbnail_url}}" width="200" alt="写真"/></a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="accept">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="accept">判定</th>
      <td mat-cell *matCellDef="let runNippon">
        <mat-select *ngIf="runNippon.accept === Accept.Posted" [(ngModel)]="runNippon.accept">
          <mat-option [value]="Accept.Accepted">採用</mat-option>
          <mat-option [value]="Accept.Rejected">不採用</mat-option>
          <mat-option [value]="Accept.Posted">未判定</mat-option>
        </mat-select>
        <mat-select *ngIf="runNippon.accept !== Accept.Posted" [(ngModel)]="runNippon.accept">
          <mat-option [value]="Accept.Accepted">採用</mat-option>
          <mat-option [value]="Accept.Rejected">不採用</mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef>{{ dataSource.data.length }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let runNippon; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-footer"></tr>
  </table>

  <div class="footer-action-container">
    <button mat-raised-button color="primary" (click)="exportToCSV()">CSV出力</button>
    <button mat-raised-button color="primary" (click)="save()">保存</button>
  </div>
</div>
