import {NgModule} from '@angular/core';

import {WidgetRoutingModule} from './widget-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../../../shared/shared.module';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {PoemComponent} from './poem/poem.component';
import {RunNipponComponent} from './run-nippon/run-nippon.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';


@NgModule({
  declarations: [
    PoemComponent,
    RunNipponComponent
  ],
  exports: [
    PoemComponent,
    RunNipponComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetRoutingModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    SharedModule,
  ]
})
export class WidgetModule {
}
