import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {API} from 'aws-amplify';
import {OverlayService} from './overlay.service';
import {MessageDialogComponent} from '../../views/partials/message-dialog/message-dialog.component';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import {RunNippon} from '../model/resources/widget';

@Injectable({
  providedIn: 'root'
})
export class RunNipponApiService {

  constructor(
    private apiHelperService: ApiHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
  ) {
  }

  async getRunNippon(): Promise<RunNippon[]> {
    if (!this.authService.userAuth?.isAllowManageWidget()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return [];
    }

    try {
      const headers = await this.apiHelperService.getBasicApiHeader();
      const init = {headers};
      return await this.overlayService.call(API.get(this.apiHelperService.apiName, `run-nippon`, init));
    } catch (e: any) {
      MessageDialogComponent.createErrorDialog(this.dialog, e.response.data.error);
      return [];
    }
  }

  async patchRunNippon(runNippon: RunNippon[]): Promise<void> {
    if (!this.authService.userAuth?.isAllowViewUsageReports()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return;
    }

    try {
      const headers = await this.apiHelperService.getBasicApiHeader();
      const init = {
        headers,
        body: {
          runNippon: runNippon.map(item => {
            return {
              uuid: item.uuid,
              accept: item.accept
            };
          })
        }
      };
      return await this.overlayService.call(API.patch(this.apiHelperService.apiName, `run-nippon`, init));
    } catch (e: any) {
      MessageDialogComponent.createErrorDialog(this.dialog, e.response.data.error);
      return;
    }
  }

}
