export type notTattaUserId = -1;

export interface Poem {
  user_id: number | notTattaUserId; // part key
  posted_at: number; // sort key
  name: string;
  upper_phrase: string;
  middle_phrase: string;
  lower_phrase: string;
  accept: Accept;
  source: 'RUNNERS' | 'TATTA' | 'DEBUG';
}

export enum Accept {
  Accepted = 0,
  Rejected = 1,
  Posted = 2,
}

export interface RunNippon {
  uuid: string; // part key
  accept: Accept; // gsi1 part key
  posted_at: number; // gsi1 sort key
  user_id: number | notTattaUserId;
  name: string;
  title: string;
  place: string;
  detail?: string;
  url: string;
  v_thumbnail_url: string;
  h_thumbnail_url: string;
}
