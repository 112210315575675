import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RunNipponApiService} from '../../../../core/services/run-nippon-api.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Accept, RunNippon} from '../../../../core/model/resources/widget';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import iconv from 'iconv-lite';
import {UtilService} from '../../../../core/services/util.service';

@Component({
  selector: 'app-run-nippon',
  templateUrl: './run-nippon.component.html',
  styleUrl: './run-nippon.component.scss'
})
export class RunNipponComponent implements OnInit {

  protected readonly Accept = Accept;

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  displayedColumns = ['user_id', 'name', 'title', 'place', 'detail', 'posted_at', 'v_thumbnail_url', 'h_thumbnail_url', 'accept'];
  dataSource: MatTableDataSource<RunNippon> = new MatTableDataSource();
  before: RunNippon[] = [];
  selectedFilter = Accept.Posted;


  constructor(private runNipponApiService: RunNipponApiService,
              private dialog: MatDialog) {
  }


  async ngOnInit(): Promise<void> {
    if (!this.sort) {
      throw new Error('sort is not set');
    }

    this.dataSource.sort = this.sort;
    this.before = await this.runNipponApiService.getRunNippon();
    await this.setFiltered();
  }

  async setFiltered(): Promise<void> {
    const changed = this.getChanged();
    if (changed.length !== 0) {
      const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '保存していない変更は破棄されます。よろしいですか？');
      if (!isOk) {
        return;
      }
    }
    const runNippon: RunNippon[] = JSON.parse(JSON.stringify(this.before));
    this.dataSource.data = runNippon.filter(item => item.accept === this.selectedFilter);
  }

  private getChanged(): RunNippon[] {
    const result: RunNippon[] = [];
    this.before.forEach(item => {
      const changed = this.dataSource.data.find(runNippon => runNippon.uuid === item.uuid &&
        runNippon.accept !== item.accept);
      if (changed) {
        result.push(changed);
      }
    });
    return result;
  }

  async save(): Promise<void> {
    const changed = this.getChanged();
    if (changed.length === 0) {
      MessageDialogComponent.createWarningDialog(this.dialog, '変更がありません。');
      return;
    }
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '変更を保存します。よろしいですか？');
    if (!isOk) {
      return;
    }

    await this.runNipponApiService.patchRunNippon(changed);
    this.before = await this.runNipponApiService.getRunNippon();
    await this.setFiltered();
  }

  async exportToCSV(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '現在表示中のデータを出力します。\n\nよろしいですか？');
    if (!isOK) {
      return;
    }

    const header = '"TATTA ID","ニックネーム","投稿日時","タイトル","場所","詳細","URL"\n';
    const body = this.dataSource.data.map((item: RunNippon) => {
      return `"${item.user_id}","${item.name}","${UtilService.getYMDHMS(item.posted_at)}","${item.title}","${item.place}","${item.detail}","${item.url}"`;
    }).join('\n');
    const csv = iconv.encode(header + body, 'windows-31j');

    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(new Blob([csv], {type: 'text/csv'}));
    a.href = url;
    a.download = 'run_nippon_export.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.parentNode!.removeChild(a);
  }
}
